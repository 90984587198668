import { ReactNode } from 'react'
import { useTranslations } from 'next-intl'
import { util } from 'protobufjs'
import Array = util.Array

export type MenuItemTypes = {
  key: string
  label: string
  isTitle?: boolean
  icon?: ReactNode
  url?: string
  badge?: {
    variant: string
    text: string
  }
  isDivider?: boolean
  parentKey?: string
  target?: string
  children?: Array<MenuItemTypes>
  auth?: boolean
}

const MENU_ITEMS: Array<MenuItemTypes> = [
  {
    key: 'home',
    label: 'Home',
    url: '/home',
    isTitle: false,
  },
  {
    key: 'landing',
    label: 'Landing',
    isTitle: false,
    children: [
      {
        key: 'app',
        label: 'App',
        url: '/landing/app',
        parentKey: 'landing',
      },
      {
        key: 'sass-classic',
        label: 'Sass Classic',
        url: '/landing/sass-classic',
        parentKey: 'landing',
      },
      {
        key: 'software',
        label: 'Software',
        url: '/landing/software',
        parentKey: 'landing',
      },
      {
        key: 'coworkng',
        label: 'Coworking',
        url: '/landing/coworking',
        parentKey: 'landing',
      },
      {
        key: 'marketing',
        label: 'Marketing',
        url: '/landing/marketing',
        parentKey: 'landing',
      },
      {
        key: 'sass-modern',
        label: 'Sass Modern',
        url: '/landing/sass-modern',
        parentKey: 'landing',
      },
      {
        key: 'startup',
        label: 'Startup',
        url: '/landing/startup',
        parentKey: 'landing',
      },
      {
        key: 'agency',
        label: 'Agency',
        url: '/landing/agency',
        parentKey: 'landing',
      },
      {
        key: 'crypto',
        label: 'Crypto',
        url: '/landing/crypto',
        parentKey: 'landing',
      },
      {
        key: 'potfolio-landing',
        label: 'Portfolio',
        url: '/landing/portfolio',
        parentKey: 'landing',
      },
    ],
  },
  {
    key: 'pages',
    label: 'Pages',
    isTitle: false,
    children: [
      {
        key: 'company',
        label: 'Company',
        url: '/pages/company',
        parentKey: 'pages',
      },
      {
        key: 'dashboard',
        label: 'Dashboard',
        url: '/dashboard',
        parentKey: 'pages',
      },
      {
        key: 'career',
        label: 'Career',
        url: '/pages/career',
        parentKey: 'pages',
      },
      {
        key: 'pricing',
        label: 'Pricing',
        url: '/pages/pricing',
        parentKey: 'pages',
      },
      {
        key: 'setting',
        label: 'Setting',
        url: '/setting',
        parentKey: 'pages',
      },
      {
        key: 'help',
        label: 'Help',
        url: '/pages/help',
        parentKey: 'pages',
      },
    ],
  },
  {
    key: 'portfolio',
    label: 'Portfolio',
    isTitle: false,
    children: [
      {
        key: 'portfolio-grid',
        url: '/pages/portfolio/grid',
        label: 'Portfolio Grid',
        parentKey: 'portfolio',
      },
      {
        key: 'portfolio-masonry',
        url: '/pages/portfolio/masonry',
        label: 'Portfolio Masonry',
        parentKey: 'portfolio',
      },
      {
        key: 'portfolio-item',
        url: '/pages/portfolio/item',
        label: 'Portfolio Item',
        parentKey: 'portfolio',
      },
    ],
  },
  {
    key: 'blogs',
    label: 'Blog',
    parentKey: 'Pages',
    isDivider: false,
    children: [
      {
        key: 'blog-page',
        label: 'Blog',
        url: '/pages/blogs/list',
        parentKey: 'blogs',
      },
      {
        key: 'blog-post',
        label: 'Blog Post',
        url: '/pages/blogs/post',
        parentKey: 'blogs',
      },
    ],
  },
  {
    key: 'auth',
    label: 'Account',
    isTitle: false,
    children: [
      {
        key: '(auth)-login',
        label: 'Login',
        url: '/auth/login',
        parentKey: 'auth',
      },
      {
        key: '(auth)-register',
        label: 'Sign Up',
        url: '/auth/register',
        parentKey: 'auth',
      },
      {
        key: '(auth)-forgot-password',
        label: 'Forget Password',
        url: '/auth/forgot-password',
        parentKey: 'auth',
      },
      {
        key: '(auth)-confirm-account',
        label: 'Confirm Account',
        url: '/auth/confirm-account',
        parentKey: 'auth',
      },
    ],
  },
  {
    key: 'contact-us',
    label: 'Contact us',
    url: '/contact-us',
    isTitle: false,
  },
]

const HORIZONTAL_MENU_ITEMS = (): Array<MenuItemTypes> => {
  const t = useTranslations('menu')

  return [
    {
      key: 'support',
      label: t('ho_tro'),
      url: '/support',
      isTitle: false,
      auth: false,
      // children: [
      //   {
      //     key: 'landing-app',
      //     label: 'App',
      //     icon: <AppIcon />,
      //     url: '/landing/app',
      //     parentKey: 'landing',
      //   },
      //   {
      //     key: 'landing-sass-classic',
      //     label: 'Sass Classic',
      //     icon: <SassClassic />,
      //     url: '/landing/sass-classic',
      //     parentKey: 'landing',
      //   },
      // ],
    },
    {
      key: 'owner',
      label: t('hop_tac'),
      url: '/owner',
      isTitle: false,
      auth: false,
    },
    {
      key: 'bookings',
      label: t('don_hang'),
      isTitle: false,
      url: '/order',
      auth: true,
    },
  ]
}

const MENU_FOOTER = () => {
  const t = useTranslations('footer')

  return [
    {
      title: t('chinh_sach'),
      links: [
        { url: '/privacy', title: t('cs_qd') },
        { url: '/terms', title: t('quy_che') },
        { url: '/dispute', title: t('giai_quyet_tranh_chap') },
      ],
    },
    {
      title: t('tim_hieu_them'),
      links: [
        { url: '/support', title: t('hoi_va_tra_loi') },
        { url: '/about-us', title: t('ve_chung_toi') },
      ],
    },
    {
      title: t('doi_tac'),
      links: [
        { url: '', title: t('career') },
        { url: '/contact', title: t('lien_he') },
      ],
    },
  ]
}

export { HORIZONTAL_MENU_ITEMS, MENU_ITEMS, MENU_FOOTER }
